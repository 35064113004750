@font-face {
    font-family: 'IQOS-Regular';
    src: url('./assets/fonts/IQOS-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'IQOS-Bold';
    src: url('./assets/fonts/IQOS-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'IQOS-Light';
    src: url('./assets/fonts/IQOS-Light.ttf') format('truetype');
}

body { 
    padding-right: 0px !important;
}

* {
    font-family: 'IQOS-Regular';
}