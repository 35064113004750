.filter-menu-item :hover {
      background-color: #00D1D2;
}

html,body{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

body::-webkit-scrollbar{
  display: none;
}

.customized-datepicker {
  text-align: center !important;
  border: 1px solid #A2A2A2 !important;
  border-radius: 5px !important;
  width: 95% !important;
  height: 39px !important;
}

.fade-in-svg {
  position: relative;
  animation: animateleft 0.4s
  }
  
  @keyframes animateleft
  {
    from {
      left: -300px;
      opacity: 0
    } 
    to {
      left: 0;
      opacity: 1
    }
}
.content-icon > svg{
  height:20px;
}

.customTable {
  border-collapse: separate;
  border-spacing: 0;
  border-top: 1px solid grey;
  scrollbar-gutter: stable both-edges;
}

.customRow {
  margin: 0;
  border: 1px solid grey;
  white-space: nowrap;
  border-top-width: 0px;
}

.broadcast-div {
  width: auto;
  overflow-x: scroll;
  overflow-y: visible;
  margin-left: 450px;
  padding: 0;
  scrollbar-gutter: stable !important;
  /* box-sizing:content-box; */
  /* box-sizing:border-box; */
}

.post-div {
  width: auto;
  overflow-x: scroll;
  overflow-y: visible;
  margin-left: 540px;
  padding: 0;
  scrollbar-gutter: stable !important;
  /* box-sizing:content-box; */
  /* box-sizing:border-box; */
}

.broadcast-div::-webkit-scrollbar, .post-div::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}

.broadcast-div::-webkit-scrollbar-track {
background: #ffffff; 
}

.post-div::-webkit-scrollbar-track {
  background: #F6FCFC;
}
 
.broadcast-div::-webkit-scrollbar-thumb, .post-div::-webkit-scrollbar-thumb {
background: #ffffff; 
}

.broadcast-div::-webkit-scrollbar-thumb:hover, .post-div::-webkit-scrollbar-thumb:hover {
background: #ffffff; 
}

.headcol {
  position: absolute;
  width: 15rem;
  left: 0;
  top: auto;
  border-top-width: 1px;
  text-overflow: ellipsis;
}

.headcol2 {
  position: absolute;
  width: 10rem;
  right: 0;
  top: auto;
  border-top-width: 1px;
}


.long {
    min-width:190px;
}

.even {
  background-color: #F6FCFC;
  border-bottom: 1px solid white !important;
}

.even-post {
  background-color: #F7F7F7;
  border-bottom: 1px solid white !important;
}

.odd {
  background-color: white;
  border-bottom: 1px solid white !important;
}