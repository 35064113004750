.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
        stroke-opacity: 0;
}


.recharts-wrapper .recharts-cartesian-grid-vertical line {
        stroke-opacity: 0;
}

.recharts-text.recharts-label {

}

.recharts-cartesian-axis-line {
        opacity: 0.9;
        background-color: transparent !important;
}


.recharts-legend-item-text {
        color: black  !important;
}